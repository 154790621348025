.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  padding: 4rem 1.6rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  max-width: 80rem;
}

.wrapper div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}

.wrapper div p {
  width: 100%;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.3rem;
  text-align: justify;
  letter-spacing: 0.01rem;
  color: var(--y-text-inverse);
}

.wrapper div h1 {
  width: 100%;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 0.01rem;
  color: var(--y-text-inverse);
}

.wrapper iframe {
  width: 100%;
  max-width: 76.8rem;
  height: 40rem;
}