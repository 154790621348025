@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
  --max-width: 110rem;
  --border-radius: 1.6rem;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
  --f-primary: #303F9F;
  --f-secondary: #3F51B5;
  --f-terciary: #4CAF50;
  --f-bg-10: #E0E0E0;
  --f-bg-20: #616161;
  --f-alert: #FFC107;
  --background-start-rgb: 1, 37, 73;
  --background-end-rgb: 97,97,97;

  --f-blue-10: #8A99A7;
  --f-blue-20: #C1C1C1;
  --f-blue-80: #012549;
  --f-blue-100: #031829;

  --f-white-30: #E6E6E6;
  --f-white-40: #F0F0F0;
  --f-white-100: #FFFFFF;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    var(--f-blue-10),
    var(--f-blue-20)
  );

  --y-scales-white: #ffffff;
  --y-scales-blue-gray-0: #f2f3f6;
  --y-scales-blue-gray-10: #EDEFF2;
  --y-scales-blue-gray-20: #d8dee6;
  --y-scales-blue-gray-30: #b5bfcf;
  --y-scales-blue-gray-40: #a7b1c0;
  --y-scales-blue-gray-50: #8c98ab;
  --y-scales-blue-gray-60: hsla(217, 12%, 48%, 1);
  --y-scales-blue-gray-70: hsla(216, 16%, 36%, 1);
  --y-scales-blue-gray-75: hsla(216, 17%, 24%, 1);
  --y-scales-blue-gray-80: hsla(217, 20%, 21%, 1);
  --y-scales-blue-gray-90: hsla(217, 20%, 14%, 1);
  --y-scales-blue-gray-100: hsla(220, 29%, 10%, 1);
  --y-scales-gray-0: #ffffff;
  --y-scales-gray-10: #f7f7f8;
  --y-scales-gray-20: #E8E9EA;
  --y-scales-gray-30: #dcdfe4;
  --y-scales-gray-40: #c5c6c9;
  --y-scales-gray-50: #afb1b6;
  --y-scales-gray-60: #a0a2a7;
  --y-scales-gray-70: #7c7e83;
  --y-scales-gray-80: #545659;
  --y-scales-gray-90: #2d2d2f;
  --y-scales-gray-100: #141415;
  --y-scales-brand-blue-0: #F4F6FD;
  --y-scales-brand-blue-10: #ecf1ff;
  --y-scales-brand-blue-20: #d9e4ff;
  --y-scales-brand-blue-30: #b3caff;
  --y-scales-brand-blue-40: #90b2fb;
  --y-scales-brand-blue-50: #6696ff;
  --y-scales-brand-blue-60: #3374FF;
  --y-scales-brand-blue-70: #0051ff;
  --y-scales-brand-blue-80: #003ab8;
  --y-scales-brand-blue-90: #001f63;
  --y-scales-brand-blue-100: #04153c;
  --y-scales-purple-10: #f7f3ff;
  --y-scales-purple-20: #efe8fe;
  --y-scales-purple-30: #dfd0fe;
  --y-scales-purple-40: #CEB7FF;
  --y-scales-purple-50: #AE87FF;
  --y-scales-purple-60: #996CF3;
  --y-scales-purple-70: #855BDD;
  --y-scales-purple-80: #6535C9;
  --y-scales-purple-90: #401D84;
  --y-scales-purple-100: #2d1877;
  --y-scales-red-10: #fdf5f5;
  --y-scales-red-20: #ffd3d3;
  --y-scales-red-30: #ffa8a8;
  --y-scales-red-40: #ff6666;
  --y-scales-red-50: #f42d2d;
  --y-scales-red-60: #d81919;
  --y-scales-red-70: #cc0000;
  --y-scales-red-80: #8f0d0d;
  --y-scales-red-90: #610909;
  --y-scales-red-100: #360505;
  --y-scales-green-10: #DFF6DF;
  --y-scales-green-20: #C8EFC8;
  --y-scales-green-30: #88DD88;
  --y-scales-green-40: #65D265;
  --y-scales-green-50: #32B332;
  --y-scales-green-60: #22A022;
  --y-scales-green-70: #138613;
  --y-scales-green-80: #097109;
  --y-scales-green-90: #054205;
  --y-scales-green-100: #022702;
  --y-scales-orange-10: #ffd4a3;
  --y-scales-orange-20: #ffbd71;
  --y-scales-orange-30: #ffa43d;
  --y-scales-orange-40: #ff961f;
  --y-scales-orange-50: #fb8600;
  --y-scales-orange-60: #cc6d00;
  --y-scales-orange-70: #a35700;
  --y-scales-orange-80: #7b4200;
  --y-scales-orange-90: #4e2b03;
  --y-scales-orange-100: #321b01;
  --y-scales-yellow-10: #FDF3D8;
  --y-scales-yellow-20: #F9E19F;
  --y-scales-yellow-30: #F7D26E;
  --y-scales-yellow-40: #F4C440;
  --y-scales-yellow-50: #F1B50E;
  --y-scales-yellow-60: #D49C02;
  --y-scales-yellow-70: #9E7605;
  --y-scales-yellow-80: #705200;
  --y-scales-yellow-90: #473400;
  --y-scales-yellow-100: #332500;
  --y-scales-decoration-green: #82d173;
  --y-scales-decoration-brown: #2d2d2a;
  --y-scales-decoration-gray: #535d6e;
  --y-scales-decoration-navy-blue: #0d3b66;
  --y-scales-decoration-blue: #89bbfe;
  --y-scales-decoration-pink: #ce7da5;
  --y-scales-decoration-red: #ff4e4e;
  --y-scales-decoration-orange: #ff8552;
  --y-scales-decoration-yellow: #fdca40;
  --y-scales-decoration-dark-green: #6bab5e;
  --y-scales-decoration-dark-brown: #252523;
  --y-scales-decoration-dark-gray: #444e5e;
  --y-scales-decoration-dark-navy-blue: #0b3153;
  --y-scales-decoration-dark-blue: #7099cf;
  --y-scales-decoration-dark-pink: #a96788;
  --y-scales-decoration-dark-red: #d14040;
  --y-scales-decoration-dark-orange: #d16d43;
  --y-scales-decoration-dark-yellow: #d0a634;
  /* day */
  --y-bg-20: var(--y-scales-blue-gray-0);
  --y-bg-30: var(--y-scales-gray-20);
  --y-bg-40: var(--y-scales-gray-30);
  --y-bg-light-brand: var(--y-scales-brand-blue-0);
  --y-bg-brand: var(--y-scales-brand-blue-70);
  --y-bg-dark: var(--y-scales-blue-gray-75);
  --y-bg-dark-brand: var(--y-scales-brand-blue-100);
  --y-bg-light: var(--y-scales-gray-0);
  --y-bg-primary: var(--y-scales-gray-10);
  --y-bg-disabled-bg: var(--y-scales-blue-gray-10);
  --y-bg-skeleton-fill: var(--y-scales-blue-gray-10);
  --y-bg-blocked-bg: var(--y-scales-red-20);
  --y-input-bg: var(--y-scales-gray-0);
  --y-input-border: var(--y-scales-blue-gray-20);
  --y-border-border-10: var(--y-scales-blue-gray-0);
  --y-border-border-20: var(--y-scales-blue-gray-10);
  --y-border-brand: var(--y-scales-brand-blue-70);
  --y-text-primary: var(--y-scales-blue-gray-75);
  --y-text-secondary: var(--y-scales-blue-gray-70);
  --y-text-tertiary: var(--y-scales-blue-gray-90);
  --y-text-placeholder20: var(--y-scales-blue-gray-50);
  --y-text-placeholder10: var(--y-scales-blue-gray-40);
  --y-text-disabled: var(--y-scales-gray-60);
  --y-text-inverse: var(--y-scales-gray-0);
  --y-text-brand: var(--y-scales-brand-blue-70);
  --y-text-risk: var(--y-scales-yellow-50);
  --y-text-danger: var(--y-scales-red-70);
  --y-text-success: var(--y-scales-green-70);
  --y-primaries-brand-blue: var(--y-scales-brand-blue-70);
  --y-primaries-red: var(--y-scales-red-70);
  --y-primaries-green: var(--y-scales-green-50);
  --y-primaries-orange: var(--y-scales-orange-50);
  --y-primaries-yellow: var(--y-scales-yellow-40);
  --y-primaries-purple: var(--y-scales-purple-60);
  --y-btn-text: var(--y-scales-blue-gray-75);
  --y-btn-hover-text: var(--y-scales-blue-gray-70);
  --y-btn-bg: var(--y-scales-gray-0);
  --y-btn-hover-bg: var(--y-scales-gray-10);
  --y-btn-border: var(--y-scales-blue-gray-10);
  --y-btn-hover-border: var(--y-scales-blue-gray-20);
  --y-btn-active-bg: var(--y-scales-gray-10);
  --y-btn-secondary-text: var(--y-scales-blue-gray-70);
  --y-btn-secondary-hover-text: var(--y-scales-blue-gray-70);
  --y-btn-secondary-bg: var(--y-scales-blue-gray-0);
  --y-btn-secondary-hover-bg: var(--y-scales-blue-gray-10);
  --y-btn-secondary-border: var(--y-scales-blue-gray-10);
  --y-btn-secondary-hover-border: var(--y-scales-blue-gray-20);
  --y-btn-secondary-active-bg: var(--y-scales-blue-gray-20);
  --y-btn-primary-text: var(--y-scales-brand-blue-70);
  --y-btn-primary-hover-text: var(--y-scales-brand-blue-80);
  --y-btn-primary-bg: var(--y-scales-brand-blue-70);
  --y-btn-primary-hover-bg: var(--y-scales-brand-blue-60);
  --y-btn-primary-border: var(--y-scales-brand-blue-70);
  --y-btn-primary-hover-border: var(--y-scales-brand-blue-60);
  --y-btn-primary-active-bg: var(--y-scales-brand-blue-70);
  --y-btn-action-text: var(--y-scales-green-60);
  --y-btn-action-hover-text: var(--y-scales-green-70);
  --y-btn-action-bg: var(--y-scales-green-50);
  --y-btn-action-hover-bg: var(--y-scales-green-60);
  --y-btn-action-border: var(--y-scales-green-60);
  --y-btn-action-hover-border: var(--y-scales-green-70);
  --y-btn-action-active-bg: var(--y-scales-green-60);
  --y-btn-danger-text: var(--y-scales-red-50);
  --y-btn-danger-hover-text: var(--y-scales-red-70);
  --y-btn-danger-bg: var(--y-scales-red-50);
  --y-btn-danger-hover-bg: var(--y-scales-red-70);
  --y-btn-danger-border: var(--y-scales-red-50);
  --y-btn-danger-hover-border: var(--y-scales-red-70);
  --y-btn-danger-active-bg: var(--y-scales-red-80);
  --y-btn-generic-active-border: var(--y-scales-brand-blue-70);
  --y-btn-generic-focus-outline: #384dc7;
  --y-btn-generic-disabled-text: var(--y-scales-gray-60);
  --y-btn-generic-disabled-bg: var(--y-scales-blue-gray-10);
  --y-btn-generic-disabled-border: var(--y-scales-gray-30);
  /* Filters */
  --y-f-btn-text: invert(16%) sepia(6%) saturate(2695%) hue-rotate(179deg) brightness(96%) contrast(80%);
  /* #333b48 */
  --y-f-text-primary: invert(21%) sepia(9%) saturate(1330%) hue-rotate(178deg) brightness(88%) contrast(87%);
  /* #333b48 */
  --y-f-text-secondary: invert(33%) sepia(37%) saturate(270%) hue-rotate(176deg) brightness(90%) contrast(89%);
  --y-f-text-brand: invert(14%) sepia(92%) saturate(7499%) hue-rotate(220deg) brightness(109%) contrast(107%);
  --y-f-placeholder-20: invert(69%) sepia(22%) saturate(271%) hue-rotate(178deg) brightness(84%) contrast(84%);
  --y-f-btn-primary-bg: invert(33%) sepia(100%) saturate(6368%) hue-rotate(221deg) brightness(99%) contrast(110%);
  --y-border-radius-m: 1.6rem;
  --y-f-btn-action-hover-text: invert(30%) sepia(99%) saturate(745%) hue-rotate(81deg) brightness(102%) contrast(94%);
  /* #138613 */
  --y-f-bg-light: invert(100%);
  --y-f-text-inverse: invert(100%);
  --y-f-text-placeholder-20: invert(71%) sepia(12%) saturate(467%) hue-rotate(178deg) brightness(83%) contrast(89%);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: all 0.3s;
  outline: none;
}

html {
  font-size: 62.55%;
}

::-webkit-scrollbar {
  display: none;
}

/* ::-webkit-scrollbar {
  width: 1rem;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 128, 255, 0.15);
}

::-webkit-scrollbar-thumb {
  background-color: var(--y-bg-brand);
  border-radius: 0.5rem;
} */

body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
ul,
li,
button {
  all: unset;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

a,
button {
  cursor: pointer;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  display: block;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-flex-i {
  display: flex !important;
}

.jc-center {
  justify-content: center;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-end {
  justify-content: flex-end;
}

.ai-center {
  align-items: center;
}

.fd-column {
  flex-direction: column;
}

.d-none {
  display: none!important;
}

.g-10 {
  gap: 1rem;
}

.g-15 {
  gap: 1.5rem;
}

.g-20 {
  gap: 2rem;
}

.g-25 {
  gap: 2.5rem;
}

.g-30 {
  gap: 3rem;
}

.flex-unset {
  flex: unset!important;
}

.show-overflow {
  overflow: unset!important;
}

.hide-overflow {
  overflow: hidden;
}

.w100 {
  width: 100%;
}

.body {
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 13rem;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.container {
  max-width: calc(120rem + 4rem);
  width: 100%;
  /* padding: 8rem 2rem; */
  /* margin: 0 auto; */
}

.tablet,
.laptop,
.desktop {
  display: none;
}

.mobile {
  display: flex;
}

@media (min-width: 425px) {
  /* Tablet */
  .mobile {
      display: none;
  }
  .tablet {
      display: flex;
  }
  .more-info>img {
      display: block;
  }
}

@media (min-width: 768px) {
  /* Laptop */
  .tablet {
      display: none;
  }
  .laptop {
      display: flex;
  }
}

@media (min-width: 1024px) {
  /* Laptop L*/
  .tablet,
  .laptop {
      display: none;
  }
  .desktop {
      display: flex;
  }
}