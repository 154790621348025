.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    transparent,
    rgb(var(--background-start-rgb))
  )
  rgb(var(--background-end-rgb));
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
  width: 100%;
  max-width: var(--max-width);
  gap: 4rem;
}
