.container {
  width: 100%;
  min-height: 100vh;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  max-width: 80rem;
}

.container h1 {
  width: 100%;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2rem;
  text-align: center;
  letter-spacing: 0.01rem;
  color: var(--y-text-inverse);
}

.container p {
  width: 100%;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.3rem;
  text-align: justify;
  letter-spacing: 0.01rem;
  color: var(--y-text-inverse);
}

.container p a {
  text-decoration: underline;
  font-style: italic;
}